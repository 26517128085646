<template>
  <div>
    <header  style="position: relative;">
      <div style=" margin: 0 auto;">
          <img style="width: 100%;height: 100%;object-fit: contain;" src="../assets/images/bossfront/Group 2176.png" />
      </div>
      <div style="position: absolute;top: 200px;left: 300px;">
        <div>
          <h1 style="font-size: 50px;"><b>服务中心</b></h1>
          <h5 style="font-size: 20px;">服务为本，客户至上，快速响应</h5>
        </div>
      </div>
      <div style="position: absolute;bottom: -110px;text-align: center;width: 100%; size: medium;">
        <div class="boss-content" style="height: 230px; display: flex;justify-content: center;align-items: center;">
          <div class="boss-content" style="box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);background-color: white;display: flex;justify-content: center;align-items: center;">
            <div class="tabhome" :class="{ active: tabPosition === 'frontserve' }"
              @click="router('serve/frontserve', 'frontserve')"><b>售前咨询</b></div>
            <div class="tabhome" :class="{ active: tabPosition === 'afterserve' }"
              @click="router('serve/afterserve', 'afterserve')"><b>售后服务</b></div>
            <div class="tabhome" :class="{ active: tabPosition === 'serve' }" @click="router('serve/serve', 'serve')">
              <b>客户成功服务</b></div>
            <div class="tabhome" :class="{ active: tabPosition === 'complain' }"
              @click="router('serve/complain', 'complain')"><b>投诉反馈</b></div>
          </div>
        </div>
      </div>

    </header>
    <main class="main">
      <div class="counseling">
        <Counseling></Counseling>
      </div>
      <div class="boss-content" style="margin-top: 30px;">
        <div class="title" style="height: 200px;position: relative;">
          <div style="position: relative;">
            <h1 style="font-size: 40px;"><b>反馈方式</b></h1>
            <div style="position: absolute;top: -15px;left: -20px; ">
              <img style="height: 50px;" src="../assets/images/bossfront/Subtract.png" alt="">
            </div>
          </div>
        </div>
        <div style=" margin: 0 auto;height:80px">
          <p style="text-align: center">
            如您遇到以下情况:客服电话未拨通、需求无人响应、工作人员态度不好、问题一直没解决等。
            欢迎拨打总经办24h投诉电话:186-8003-7498，我们将竭诚为您服务!
          </p>
        </div>
        <div style=" margin: 0 auto;">
          <el-row :gutter="40">
            <el-col :xs="12" :md="12">
              <div class="grid-content bg-purple">
                <el-row style="
                  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
                  height: 180px;
                  padding: 20px 0px 0px 20px;
                ">
                  <el-col :xs="4" :md="5">
                    <img src="../assets/images/bosscomplain/Group 2155.png" />
                  </el-col>
                  <el-col :xs="12" :md="13">
                    <h3>需求反馈</h3>
                    <h4 style="color: #00f">提交需求&nbsp;&nbsp; <b>→</b></h4>
                    <p>给产品经理提功能需求、用户体验、产品文档等建议</p>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :xs="12" :md="12">
              <div class="grid-content bg-purple">
                <el-row style="
                  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
                  height: 180px;
                  padding: 20px 0px 0px 20px;
                ">
                  <el-col :xs="4" :md="5">
                    <img src="../assets/images/bosscomplain/Group 1952.png" />
                  </el-col>
                  <el-col :xs="12" :md="13">
                    <h3>投诉</h3>
                    <h4>总经办24h电话</h4>
                    <h4 style="color: #00f">186-8003-7498</h4>
                    <p>24小时在线，15分钟回复</p>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

    </main>
  </div>
</template>

<script>
import Counseling from "../views/Counseling.vue";
export default {
  components: {
    Counseling,
  },
  data() {
    return {
      tabPosition: 'complain'

    };
  },
  computed: {},
  watch: {},
  methods: {
    router(val,tab) {
      this.$router.push("/" + val + "/");
      this.tabPosition=tab
    }

  },
  created() { },
  mounted() {

  },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.tabhome{
  background-color: white;
  margin-top: 0px;
  height: 76px;
  width: 230px;  
  display: flex;justify-content: center;align-items: center;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
}
.tabhome.active {
  background: url('../assets/images/bossfront/Group 2148.png') no-repeat;
  background-size: 100%;
}
.el-row {
  margin-bottom: 20px;
}

.bg-purple {
  background: white;
}

.grid-content {
  min-height: 150px;
}

img {
  height: 70px;
}

.title {
  height: 160px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  background: url("../assets/images/bossfront/Group 2176.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 500px;
  position: relative;
  z-index: 1;
}

.counseling {
z-index: 10;
right: 28px;
position: relative;
position: fixed;
width: 56px;
height: 344px;
bottom: 40px;
background: rgba(255, 255, 255, 0.8);
box-shadow: 0px 4px 8px 0px rgba(44, 81, 154, 0.16);
border-radius: 12px 12px 12px 12px;
border: 1px solid #DCE0E4;
}
</style>